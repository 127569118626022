import { graphql, useStaticQuery } from 'gatsby';
import GenerateReact from '../components/generate-react';
import React from 'react';
import { connect } from 'react-redux';
import { updatePath } from '../redux/actions';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
const Services = (props) => {
  const data = useStaticQuery(query);
  React.useEffect(() => {
    props.reduxUpdatePath(props.uri);
  }, []);
  return (
    <>
      <Helmet title="Services | Bellisima" />
      <GenerateReact data={data} />
    </>
  );
};
const query = graphql`
  query ServicesPage {
    page: contentfulPage(contentful_id: { eq: "1ORRFftvY24OTvUoFnItFv" }) {
      ...DataTypes
    }
  }
`;
Services.propTypes = {
  uri: PropTypes.string,
  reduxUpdatePath: PropTypes.func,
};
const mapDispatchToProps = (dispatch) => ({
  reduxUpdatePath: (data) => dispatch(updatePath(data)),
});
export default connect(null, mapDispatchToProps)(Services);
